var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("tev-navbar", {
        attrs: {
          backTo: "/compliance",
          title: _vm.propertyId ? "Edit Invitation" : "New Invitation",
        },
      }),
      _vm.isAddProperty
        ? _c(
            "v-content",
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "", "pt-4": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _vm.isLoadingInvitation
                        ? _c(
                            "div",
                            { staticClass: "text-xs-center pa-5" },
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  size: 50,
                                  color: "primary",
                                  indeterminate: "",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isLoadingInvitation
                        ? _c(
                            "v-form",
                            { ref: "form" },
                            [
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c("v-flex", [
                                        _c(
                                          "span",
                                          { staticClass: "grey--text" },
                                          [_vm._v("Management Company")]
                                        ),
                                        _c("h2", { staticClass: "mb-4" }, [
                                          _vm._v(_vm._s(_vm.subscriberName)),
                                        ]),
                                      ]),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            "text-xs-right": "",
                                            "hidden-xs-only": "",
                                          },
                                        },
                                        [
                                          _vm.propertyId &&
                                          _vm.status == "Pending"
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass: "white--text",
                                                  attrs: {
                                                    color: "primary",
                                                    outline: "",
                                                    large: "",
                                                    depressed: "",
                                                  },
                                                  on: {
                                                    click: _vm.confirmSend,
                                                  },
                                                },
                                                [_vm._v("Resend Invitation")]
                                              )
                                            : _vm._e(),
                                          !_vm.propertyId
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    large: "",
                                                    depressed: "",
                                                  },
                                                  on: {
                                                    click: _vm.confirmSend,
                                                  },
                                                },
                                                [_vm._v("Invite Now")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", sm12: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              "browser-autocomplete": "off",
                                              label: "Community Name",
                                              autofocus: _vm.$isDesktop(),
                                              rules: _vm.rules.required,
                                              required: "",
                                              box: "",
                                            },
                                            model: {
                                              value: _vm.propertyName,
                                              callback: function ($$v) {
                                                _vm.propertyName = $$v
                                              },
                                              expression: "propertyName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", sm6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              "browser-autocomplete": "off",
                                              label: "Editor Firstname",
                                              rules: _vm.rules.required,
                                              required: "",
                                              box: "",
                                            },
                                            model: {
                                              value: _vm.editorFirstName,
                                              callback: function ($$v) {
                                                _vm.editorFirstName = $$v
                                              },
                                              expression: "editorFirstName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", sm6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              "browser-autocomplete": "off",
                                              label: "Editor Lastname",
                                              rules: _vm.rules.required,
                                              required: "",
                                              box: "",
                                            },
                                            model: {
                                              value: _vm.editorLastName,
                                              callback: function ($$v) {
                                                _vm.editorLastName = $$v
                                              },
                                              expression: "editorLastName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", sm6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              "browser-autocomplete": "off",
                                              label: "Editor Email",
                                              rules: _vm.rules.email,
                                              required: "",
                                              box: "",
                                            },
                                            model: {
                                              value: _vm.editorEmail,
                                              callback: function ($$v) {
                                                _vm.editorEmail = $$v
                                              },
                                              expression: "editorEmail",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", sm6: "" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              clearable: true,
                                              items: _vm.salesreps,
                                              "item-value": "id",
                                              "item-text": "name",
                                              label: "Sales Representative",
                                              box: "",
                                            },
                                            on: {
                                              change: _vm.deleteCommission,
                                            },
                                            model: {
                                              value: _vm.salesRepId,
                                              callback: function ($$v) {
                                                _vm.salesRepId = $$v
                                              },
                                              expression: "salesRepId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.salesRepId
                                    ? _c(
                                        "v-layout",
                                        { attrs: { row: "", wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "", sm12: "" } },
                                            [
                                              _c("v-checkbox", {
                                                attrs: {
                                                  color: "primary",
                                                  label:
                                                    "Commission schedule edit",
                                                },
                                                model: {
                                                  value: _vm.hasCommission,
                                                  callback: function ($$v) {
                                                    _vm.hasCommission = $$v
                                                  },
                                                  expression: "hasCommission",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("v-flex", {
                                            attrs: { xs1: "", sm1: "" },
                                          }),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs2: "", sm2: "" } },
                                            [
                                              _vm.hasCommission
                                                ? _c("v-text-field", {
                                                    attrs: {
                                                      type: "number",
                                                      label: "% 1st Year",
                                                      box: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.commissionYears[0],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.commissionYears,
                                                          0,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "commissionYears[0]",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs2: "", sm2: "" } },
                                            [
                                              _vm.hasCommission
                                                ? _c("v-text-field", {
                                                    attrs: {
                                                      type: "number",
                                                      label: "% 2nd Year",
                                                      box: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.commissionYears[1],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.commissionYears,
                                                          1,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "commissionYears[1]",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs2: "", sm2: "" } },
                                            [
                                              _vm.hasCommission
                                                ? _c("v-text-field", {
                                                    attrs: {
                                                      type: "number",
                                                      label: "% 3rd Year",
                                                      box: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.commissionYears[2],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.commissionYears,
                                                          2,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "commissionYears[2]",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs2: "", sm2: "" } },
                                            [
                                              _vm.hasCommission
                                                ? _c("v-text-field", {
                                                    attrs: {
                                                      type: "number",
                                                      label: "% 4th Year",
                                                      box: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.commissionYears[3],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.commissionYears,
                                                          3,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "commissionYears[3]",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs2: "", sm2: "" } },
                                            [
                                              _vm.hasCommission
                                                ? _c("v-text-field", {
                                                    attrs: {
                                                      type: "number",
                                                      label: "% 5th Year",
                                                      box: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.commissionYears[4],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.commissionYears,
                                                          4,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "commissionYears[4]",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: { row: "", wrap: "", "mb-5": "" },
                                    },
                                    [
                                      !_vm.disablePricingInformation
                                        ? _c(
                                            "v-flex",
                                            { attrs: { xs12: "", "pa-2": "" } },
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  staticClass:
                                                    "mb-4 pa-2 elevation-0 text-xs-center",
                                                },
                                                [
                                                  _c("UploadButton", {
                                                    attrs: {
                                                      color: _vm.proposalDocUrl
                                                        ? "black"
                                                        : "primary",
                                                      labelClass: "white--text",
                                                      large: "",
                                                      depressed: "",
                                                      title: _vm.proposalDocUrl
                                                        ? _vm.proposalDocUrl
                                                            .split(/(\\|\/)/g)
                                                            .pop()
                                                            .split(/\?/g)
                                                            .shift()
                                                        : "Upload Proposal",
                                                      multiple: false,
                                                      done: _vm.uploadProposalDoc,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "", wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                sm6: "",
                                                "pa-2": "",
                                              },
                                            },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  items: [0, 5, 10],
                                                  label: "Number of documents",
                                                  box: "",
                                                },
                                                model: {
                                                  value: _vm.extraDocLimit,
                                                  callback: function ($$v) {
                                                    _vm.extraDocLimit = $$v
                                                  },
                                                  expression: "extraDocLimit",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                sm6: "",
                                                "pa-2": "",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  type: "number",
                                                  label: "Documents price $",
                                                  required: "",
                                                  box: "",
                                                },
                                                model: {
                                                  value: _vm.docPrice,
                                                  callback: function ($$v) {
                                                    _vm.docPrice = $$v
                                                  },
                                                  expression: "docPrice",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "", wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                sm6: "",
                                                "pa-2": "",
                                              },
                                            },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  items: [
                                                    "Quick Approve Lite",
                                                    "Quick Approve Plus",
                                                    "Confirmation Letter Lite",
                                                    "Confirmation Letter Plus",
                                                    "None",
                                                  ],
                                                  label: "Quick approve",
                                                  box: "",
                                                },
                                                model: {
                                                  value: _vm.quickApprove,
                                                  callback: function ($$v) {
                                                    _vm.quickApprove = $$v
                                                  },
                                                  expression: "quickApprove",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                sm6: "",
                                                "pa-2": "",
                                              },
                                            },
                                            [
                                              _vm.quickApprove != "None"
                                                ? _c("v-text-field", {
                                                    attrs: {
                                                      type: "number",
                                                      label:
                                                        "Quick approve price $",
                                                      required: "",
                                                      box: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.quickApprovePrice,
                                                      callback: function ($$v) {
                                                        _vm.quickApprovePrice =
                                                          $$v
                                                      },
                                                      expression:
                                                        "quickApprovePrice",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                sm6: "",
                                                "pa-2": "",
                                              },
                                            },
                                            [
                                              _c("v-checkbox", {
                                                attrs: {
                                                  color: "primary",
                                                  label:
                                                    "QA letter customization?",
                                                },
                                                on: {
                                                  change:
                                                    _vm.handleResetLetterPrice,
                                                },
                                                model: {
                                                  value: _vm.qaLetter,
                                                  callback: function ($$v) {
                                                    _vm.qaLetter = $$v
                                                  },
                                                  expression: "qaLetter",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                sm6: "",
                                                "pa-2": "",
                                              },
                                            },
                                            [
                                              _vm.qaLetter
                                                ? _c("v-text-field", {
                                                    attrs: {
                                                      type: "number",
                                                      label:
                                                        "QA letter customization price $",
                                                      min: "30",
                                                      required: "",
                                                      box: "",
                                                    },
                                                    model: {
                                                      value: _vm.qaLetterPrice,
                                                      callback: function ($$v) {
                                                        _vm.qaLetterPrice = $$v
                                                      },
                                                      expression:
                                                        "qaLetterPrice",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                sm6: "",
                                                "pa-2": "",
                                              },
                                            },
                                            [
                                              _c("v-checkbox", {
                                                attrs: {
                                                  color: "primary",
                                                  label: "Deluxe fee",
                                                },
                                                on: {
                                                  change:
                                                    _vm.handleResetDeluxeFee,
                                                },
                                                model: {
                                                  value: _vm.deluxeFee,
                                                  callback: function ($$v) {
                                                    _vm.deluxeFee = $$v
                                                  },
                                                  expression: "deluxeFee",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                sm6: "",
                                                "pa-2": "",
                                              },
                                            },
                                            [
                                              _vm.deluxeFee
                                                ? _c("v-text-field", {
                                                    attrs: {
                                                      type: "number",
                                                      label:
                                                        "Deluxe fee price $",
                                                      min: "100",
                                                      required: "",
                                                      box: "",
                                                    },
                                                    model: {
                                                      value: _vm.deluxeFeePrice,
                                                      callback: function ($$v) {
                                                        _vm.deluxeFeePrice = $$v
                                                      },
                                                      expression:
                                                        "deluxeFeePrice",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                sm6: "",
                                                "pa-2": "",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  type: "number",
                                                  label:
                                                    "Proof of income price $",
                                                  required: "",
                                                  box: "",
                                                },
                                                model: {
                                                  value: _vm.proofOfIncomePrice,
                                                  callback: function ($$v) {
                                                    _vm.proofOfIncomePrice = $$v
                                                  },
                                                  expression:
                                                    "proofOfIncomePrice",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", "mb-3": "" } },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "mb-4 px-2 elevation-0",
                                            },
                                            [
                                              _c(
                                                "h3",
                                                {
                                                  staticClass:
                                                    "subheading d-inline-block blue--line px-2",
                                                },
                                                [
                                                  _c("v-checkbox", {
                                                    attrs: {
                                                      color: "primary",
                                                      label: "Rental Package",
                                                    },
                                                    model: {
                                                      value: _vm.allowLease,
                                                      callback: function ($$v) {
                                                        _vm.allowLease = $$v
                                                      },
                                                      expression: "allowLease",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: { row: "", wrap: "" },
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        sm6: "",
                                                        "pa-2": "",
                                                      },
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items: _vm.packages,
                                                          "item-value": "id",
                                                          "item-text":
                                                            "description",
                                                          label: "Individuals",
                                                          disabled:
                                                            !_vm.allowLease,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.tenantPackageId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.tenantPackageId =
                                                              $$v
                                                          },
                                                          expression:
                                                            "tenantPackageId",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        sm6: "",
                                                        "pa-2": "",
                                                      },
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items: _vm.packages,
                                                          "item-value": "id",
                                                          "item-text":
                                                            "description",
                                                          label:
                                                            "Married Couples",
                                                          disabled:
                                                            !_vm.allowLease,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.tenantSpousePackageId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.tenantSpousePackageId =
                                                              $$v
                                                          },
                                                          expression:
                                                            "tenantSpousePackageId",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        "pa-2": "",
                                                      },
                                                    },
                                                    [
                                                      _c("v-checkbox", {
                                                        attrs: {
                                                          color: "primary",
                                                          label:
                                                            "Proof of income rental?",
                                                          disabled:
                                                            !_vm.allowLease,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.proofOfIncomeRental,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.proofOfIncomeRental =
                                                              $$v
                                                          },
                                                          expression:
                                                            "proofOfIncomeRental",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "mb-4 px-2 elevation-0",
                                            },
                                            [
                                              _c(
                                                "h3",
                                                {
                                                  staticClass:
                                                    "subheading d-inline-block blue--line px-2",
                                                },
                                                [
                                                  _c("v-checkbox", {
                                                    attrs: {
                                                      color: "primary",
                                                      label: "Purchase Package",
                                                    },
                                                    model: {
                                                      value: _vm.allowPurchase,
                                                      callback: function ($$v) {
                                                        _vm.allowPurchase = $$v
                                                      },
                                                      expression:
                                                        "allowPurchase",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: { row: "", wrap: "" },
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        sm6: "",
                                                        "pa-2": "",
                                                      },
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items: _vm.packages,
                                                          "item-value": "id",
                                                          "item-text":
                                                            "description",
                                                          label: "Individuals",
                                                          disabled:
                                                            !_vm.allowPurchase,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.purchasePackageId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.purchasePackageId =
                                                              $$v
                                                          },
                                                          expression:
                                                            "purchasePackageId",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        sm6: "",
                                                        "pa-2": "",
                                                      },
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items: _vm.packages,
                                                          "item-value": "id",
                                                          "item-text":
                                                            "description",
                                                          label:
                                                            "Married Couples",
                                                          disabled:
                                                            !_vm.allowPurchase,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.purchaseSpousePackageId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.purchaseSpousePackageId =
                                                              $$v
                                                          },
                                                          expression:
                                                            "purchaseSpousePackageId",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        "pa-2": "",
                                                      },
                                                    },
                                                    [
                                                      _c("v-checkbox", {
                                                        attrs: {
                                                          color: "primary",
                                                          label:
                                                            "Proof of income purchase?",
                                                          disabled:
                                                            !_vm.allowPurchase,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.proofOfIncomePurchase,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.proofOfIncomePurchase =
                                                              $$v
                                                          },
                                                          expression:
                                                            "proofOfIncomePurchase",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "mb-4 px-2 elevation-0",
                                            },
                                            [
                                              _c(
                                                "h3",
                                                {
                                                  staticClass:
                                                    "subheading d-inline-block blue--line px-2",
                                                },
                                                [
                                                  _c("v-checkbox", {
                                                    attrs: {
                                                      color: "primary",
                                                      label: "Guest Package",
                                                    },
                                                    model: {
                                                      value: _vm.allowGuest,
                                                      callback: function ($$v) {
                                                        _vm.allowGuest = $$v
                                                      },
                                                      expression: "allowGuest",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: { row: "", wrap: "" },
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        sm6: "",
                                                        "pa-2": "",
                                                      },
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items: _vm.packages,
                                                          "item-value": "id",
                                                          "item-text":
                                                            "description",
                                                          label: "Individuals",
                                                          disabled:
                                                            !_vm.allowGuest,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.guestPackageId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.guestPackageId =
                                                              $$v
                                                          },
                                                          expression:
                                                            "guestPackageId",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        sm6: "",
                                                        "pa-2": "",
                                                      },
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items: _vm.packages,
                                                          "item-value": "id",
                                                          "item-text":
                                                            "description",
                                                          label:
                                                            "Married Couples",
                                                          disabled:
                                                            !_vm.allowGuest,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.guestSpousePackageId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.guestSpousePackageId =
                                                              $$v
                                                          },
                                                          expression:
                                                            "guestSpousePackageId",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        "pa-2": "",
                                                      },
                                                    },
                                                    [
                                                      _c("v-checkbox", {
                                                        attrs: {
                                                          color: "primary",
                                                          label:
                                                            "Proof of income guest?",
                                                          disabled:
                                                            !_vm.allowGuest,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.proofOfIncomeGuest,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.proofOfIncomeGuest =
                                                              $$v
                                                          },
                                                          expression:
                                                            "proofOfIncomeGuest",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "mb-4 px-2 elevation-0",
                                            },
                                            [
                                              _c(
                                                "h3",
                                                {
                                                  staticClass:
                                                    "subheading d-inline-block blue--line py-3 px-2 mb-3",
                                                },
                                                [
                                                  _vm._v(
                                                    "Fees charged to the applicants"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: { row: "", wrap: "" },
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        sm4: "",
                                                        "px-2": "",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          type: "number",
                                                          label: "Individual",
                                                          required: "",
                                                        },
                                                        model: {
                                                          value: _vm.tenantFee,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.tenantFee = $$v
                                                          },
                                                          expression:
                                                            "tenantFee",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        sm4: "",
                                                        "px-2": "",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          type: "number",
                                                          label:
                                                            "Married couple",
                                                          required: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.tenantSpouseFee,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.tenantSpouseFee =
                                                              $$v
                                                          },
                                                          expression:
                                                            "tenantSpouseFee",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        sm4: "",
                                                        "px-2": "",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          type: "number",
                                                          label:
                                                            "Roomates (others)",
                                                          required: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.tenantRoommateFee,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.tenantRoommateFee =
                                                              $$v
                                                          },
                                                          expression:
                                                            "tenantRoommateFee",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: { row: "", wrap: "" },
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        sm4: "",
                                                        "px-2": "",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          type: "number",
                                                          label:
                                                            "Guest application",
                                                          required: "",
                                                        },
                                                        model: {
                                                          value: _vm.guestFee,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.guestFee = $$v
                                                          },
                                                          expression:
                                                            "guestFee",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        sm4: "",
                                                        "px-2": "",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          type: "number",
                                                          label:
                                                            "Guest Married couple",
                                                          required: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.guestSpouseFee,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.guestSpouseFee =
                                                              $$v
                                                          },
                                                          expression:
                                                            "guestSpouseFee",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        sm4: "",
                                                        "px-2": "",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          type: "number",
                                                          label:
                                                            "Guest Roomates (others)",
                                                          required: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.guestRoommateFee,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.guestRoommateFee =
                                                              $$v
                                                          },
                                                          expression:
                                                            "guestRoommateFee",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "mb-4 px-2 elevation-0",
                                            },
                                            [
                                              _c(
                                                "h3",
                                                {
                                                  staticClass:
                                                    "subheading d-inline-block blue--line py-3 px-2 mb-2",
                                                },
                                                [_vm._v("Rush handling fee")]
                                              ),
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: { row: "", wrap: "" },
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        sm4: "",
                                                        "pa-2": "",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          type: "number",
                                                          label:
                                                            "Charged to applicant",
                                                          required: "",
                                                        },
                                                        model: {
                                                          value: _vm.rushFee,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.rushFee = $$v
                                                          },
                                                          expression: "rushFee",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        sm4: "",
                                                        "pa-2": "",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          type: "number",
                                                          label:
                                                            "Retained by TE",
                                                          required: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.rushFeeRetained,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.rushFeeRetained =
                                                              $$v
                                                          },
                                                          expression:
                                                            "rushFeeRetained",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        sm4: "",
                                                        "pa-2": "",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          type: "number",
                                                          value: _vm.rushFeeDue,
                                                          label:
                                                            "Due to client",
                                                          readonly: "",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            sm12: "",
                                            "mb-5": "",
                                            "text-xs-center": "",
                                          },
                                        },
                                        [
                                          _vm.propertyId &&
                                          _vm.status == "Pending"
                                            ? [
                                                _c(
                                                  "v-layout",
                                                  {
                                                    attrs: {
                                                      "align-center": "",
                                                      "justify-space-between":
                                                        "",
                                                      row: "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "white--text",
                                                        attrs: {
                                                          color: "red",
                                                          outline: "",
                                                          large: "",
                                                          depressed: "",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.confirmCancel,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Cancel Invitation"
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "white--text",
                                                        attrs: {
                                                          color: "primary",
                                                          outline: "",
                                                          large: "",
                                                          depressed: "",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.confirmSend,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Resend Invitation"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            : _vm._e(),
                                          !_vm.propertyId
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    large: "",
                                                    depressed: "",
                                                  },
                                                  on: {
                                                    click: _vm.confirmSend,
                                                  },
                                                },
                                                [_vm._v("Invite Now")]
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { width: "600" },
          model: {
            value: _vm.showSendDialog,
            callback: function ($$v) {
              _vm.showSendDialog = $$v
            },
            expression: "showSendDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline grey white--text",
                  attrs: { "primary-title": "" },
                },
                [
                  _c("span", [_vm._v("Review & Confirm")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", color: "white", flat: "" },
                      on: {
                        click: function ($event) {
                          _vm.showSendDialog = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Community Name"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.propertyName || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Company Manager"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.subscriberName || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Editor Firstname"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.editorFirstName || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Editor Lastname"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.editorLastName || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Editor Email"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.editorEmail || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Sell Representative"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.salesRepName || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm.hasCommission
                        ? _c(
                            "v-flex",
                            { attrs: { xs12: "", sm6: "", "mb-3": "" } },
                            [
                              _c("div", { staticClass: "grey--text" }, [
                                _vm._v("Commission Schedule"),
                              ]),
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.commissionYears[0] +
                                      "%, " +
                                      _vm.commissionYears[1] +
                                      "%, " +
                                      _vm.commissionYears[2] +
                                      "%, " +
                                      _vm.commissionYears[3] +
                                      "%, " +
                                      _vm.commissionYears[4] +
                                      "%" || "-"
                                  ) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Number of documents"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.extraDocLimit || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Documents price"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.docPrice || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Quick approve"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.quickApprove || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Quick approve price"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.quickApprovePrice || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("QA letter customization?"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.qaLetter || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("QA letter customization price"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.qaLetterPrice || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Deluxe fee"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.deluxeFee || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Deluxe fee price"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.deluxeFeePrice || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Proof of income rental?"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.proofOfIncomeRental || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Proof of income purchase?"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.proofOfIncomePurchase || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Proof of income guest?"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.proofOfIncomeGuest || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Proof of income price"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.proofOfIncomePrice || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c("v-flex", { attrs: { xs12: "", "mb-3": "" } }, [
                        _c("strong", [_vm._v("Rental Package")]),
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Individuals"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.tenantPackageId || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Married Couples"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.tenantSpousePackageId || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c("v-flex", { attrs: { xs12: "", "mb-3": "" } }, [
                        _c("strong", [_vm._v("Purchase Package")]),
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Individuals"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.purchasePackageId || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Married Couples"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.purchaseSpousePackageId || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c("v-flex", { attrs: { xs12: "", "mb-3": "" } }, [
                        _c("strong", [_vm._v("Guest Package")]),
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Individuals"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.guestPackageId || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Married Couples"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.guestSpousePackageId || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c("v-flex", { attrs: { xs12: "", "mb-3": "" } }, [
                        _c("strong", [
                          _vm._v("Fees charged to the applicants"),
                        ]),
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Individuals"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.tenantFee || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Married Couples"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.tenantSpouseFee || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Roomates (others)"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.tenantRoommateFee || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Guest application"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.guestFee || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Guest Married Couples"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.guestSpouseFee || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Guest Roomates (others)"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.guestRoommateFee || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c("v-flex", { attrs: { xs12: "", "mb-3": "" } }, [
                        _c("strong", [_vm._v("Rush handling fee")]),
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Charged to applicant"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.rushFee || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Retained by TE"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.rushFeeRetained || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "mb-3": "" } },
                        [
                          _c("div", { staticClass: "grey--text" }, [
                            _vm._v("Due to client"),
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.rushFeeDue || "-") +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c("v-btn", {
                    class: {
                      "red--text": _vm.isLoading,
                      "white--text": !_vm.isLoading,
                    },
                    attrs: {
                      color: "primary",
                      loading: _vm.isLoading,
                      disabled: _vm.isLoading,
                      large: "",
                      depressed: "",
                    },
                    domProps: {
                      textContent: _vm._s(
                        _vm.propertyId && _vm.status == "Pending"
                          ? "Resend Invitation"
                          : "Send Invitation"
                      ),
                    },
                    on: { click: _vm.inviteNow },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "600" },
          model: {
            value: _vm.showCancelDialog,
            callback: function ($$v) {
              _vm.showCancelDialog = $$v
            },
            expression: "showCancelDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline grey white--text",
                  attrs: { "primary-title": "" },
                },
                [
                  _c("span", [_vm._v("Confirm Cancel")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", color: "white", flat: "" },
                      on: {
                        click: function ($event) {
                          _vm.showCancelDialog = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-text", [
                _vm._v(
                  "When canceling the invitation the property manager will not be able to access the property. Are you sure you want to cancel it?"
                ),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c("v-btn", {
                    class: {
                      "red--text": _vm.isLoading,
                      "white--text": !_vm.isLoading,
                    },
                    attrs: {
                      color: "red",
                      loading: _vm.isLoading,
                      large: "",
                      depressed: "",
                      disabled: _vm.isLoading,
                    },
                    domProps: { textContent: _vm._s("Yes, Cancel Invitation") },
                    on: { click: _vm.cancelNow },
                  }),
                  _c("v-btn", {
                    attrs: {
                      color: "red",
                      outline: "",
                      large: "",
                      depressed: "",
                      disabled: _vm.isLoading,
                    },
                    domProps: { textContent: _vm._s("No") },
                    on: {
                      click: function ($event) {
                        _vm.showCancelDialog = false
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.isAddProperty
        ? _c(
            "v-content",
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "", "pt-4": "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "mb-5", attrs: { xs12: "", sm12: "" } },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "mb-5",
                                  attrs: {
                                    xs12: "",
                                    sm8: "",
                                    "offset-sm2": "",
                                  },
                                },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        wrap: "",
                                        "align-center": "",
                                        "justify-space-between": "",
                                        row: "",
                                      },
                                    },
                                    [
                                      _c("h4", { staticClass: "ma-0" }, [
                                        _vm._v("SUBSCRIBERS"),
                                      ]),
                                    ]
                                  ),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Search for a subscriber...",
                                      "append-icon": "search",
                                      autofocus: _vm.$isDesktop(),
                                      clearable: "",
                                      "single-line": "",
                                      box: "",
                                    },
                                    model: {
                                      value: _vm.query,
                                      callback: function ($$v) {
                                        _vm.query = $$v
                                      },
                                      expression: "query",
                                    },
                                  }),
                                  _vm.isLoading
                                    ? _c(
                                        "div",
                                        { staticClass: "text-xs-center py-4" },
                                        [
                                          _c("v-progress-circular", {
                                            attrs: {
                                              size: 50,
                                              color: "primary",
                                              indeterminate: "",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.isLoading && _vm.subscribers.length
                                    ? _c(
                                        "v-list",
                                        {
                                          class: {
                                            "pb-0":
                                              _vm.$vuetify.breakpoint.smAndDown,
                                          },
                                          attrs: {
                                            "two-line": "",
                                            subheader: "",
                                          },
                                        },
                                        _vm._l(
                                          _vm.subscribers,
                                          function (item, index) {
                                            return _c(
                                              "v-list-tile",
                                              {
                                                key: index,
                                                attrs: { avatar: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addProperty({
                                                      subscriberId: item.id,
                                                      subscriberName:
                                                        item.description,
                                                      disablePricingInformation:
                                                        item.disablePricingInformation,
                                                    })
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-list-tile-content",
                                                  [
                                                    _c("v-list-tile-title", [
                                                      _vm._v(
                                                        _vm._s(item.description)
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-tile-action",
                                                  [
                                                    _c(
                                                      "v-chip",
                                                      {
                                                        staticClass:
                                                          "chip-fluid",
                                                        attrs: {
                                                          color: "primary",
                                                          "text-color": "white",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "#" + _vm._s(item.id)
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    fixed: "",
                    dark: "",
                    fab: "",
                    bottom: "",
                    right: "",
                    color: "red",
                    to: "/compliance",
                  },
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }