<template>
  <v-app>
    <tev-navbar backTo="/compliance" :title="propertyId? 'Edit Invitation' : 'New Invitation'" />
    <v-content v-if="isAddProperty">
      <v-layout row wrap pt-4>
        <v-flex xs12>
          <div class="text-xs-center pa-5" v-if="isLoadingInvitation">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
          </div>
          <v-form ref="form" v-if="!isLoadingInvitation">
            <v-container>
              <v-layout row wrap>
                <v-flex>
                  <span class="grey--text">Management Company</span>
                  <h2 class="mb-4">{{subscriberName}}</h2>
                </v-flex>
                <v-flex text-xs-right hidden-xs-only>
                  <v-btn
                    color="primary"
                    class="white--text"
                    outline
                    large
                    depressed
                    @click="confirmSend"
                    v-if="propertyId&&status=='Pending'"
                  >Resend Invitation</v-btn>
                  <v-btn
                    color="primary"
                    large
                    depressed
                    @click="confirmSend"
                    v-if="!propertyId"
                  >Invite Now</v-btn>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 sm12>
                  <v-text-field
                    browser-autocomplete="off"
                    v-model="propertyName"
                    label="Community Name"
                    :autofocus="$isDesktop()"
                    :rules="rules.required"
                    required
                    box
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    browser-autocomplete="off"
                    v-model="editorFirstName"
                    label="Editor Firstname"
                    :rules="rules.required"
                    required
                    box
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    browser-autocomplete="off"
                    v-model="editorLastName"
                    label="Editor Lastname"
                    :rules="rules.required"
                    required
                    box
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    browser-autocomplete="off"
                    v-model="editorEmail"
                    label="Editor Email"
                    :rules="rules.email"
                    required
                    box
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-select
                    v-model="salesRepId"
                    :clearable="true"
                    :items="salesreps"
                    v-on:change="deleteCommission"
                    item-value="id"
                    item-text="name"
                    label="Sales Representative"
                    box
                  ></v-select>
                </v-flex>
              </v-layout>
              <v-layout row wrap v-if="salesRepId">
                <v-flex xs12 sm12>
                  <v-checkbox color="primary" label="Commission schedule edit" v-model="hasCommission"></v-checkbox>
                </v-flex>
                <v-flex xs1 sm1>
                </v-flex>
                <v-flex xs2 sm2>
                  <v-text-field
                    type="number"
                    v-model="commissionYears[0]"
                    label="% 1st Year"
                    box
                    v-if="hasCommission"
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2>
                  <v-text-field
                    type="number"
                    v-model="commissionYears[1]"
                    label="% 2nd Year"
                    box
                    v-if="hasCommission"
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2>
                  <v-text-field
                    type="number"
                    v-model="commissionYears[2]"
                    label="% 3rd Year"
                    box
                    v-if="hasCommission"
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2>
                  <v-text-field
                    type="number"
                    v-model="commissionYears[3]"
                    label="% 4th Year"
                    box
                    v-if="hasCommission"
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2>
                  <v-text-field
                    type="number"
                    v-model="commissionYears[4]"
                    label="% 5th Year"
                    box
                    v-if="hasCommission"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap mb-5>
                <v-flex xs12 pa-2 v-if="!disablePricingInformation">
                  <v-card class="mb-4 pa-2 elevation-0 text-xs-center">
                    <UploadButton
                      :color="proposalDocUrl?'black':'primary'"
                      labelClass="white--text"
                      large
                      depressed
                      v-bind:title="proposalDocUrl?proposalDocUrl.split(/(\\|\/)/g).pop().split(/\?/g).shift():'Upload Proposal'"
                      v-bind:multiple="false"
                      v-bind:done="uploadProposalDoc"
                    ></UploadButton>
                  </v-card>
                </v-flex>

                <v-layout row wrap>
                  <v-flex xs12 sm6 pa-2>
                    <v-select
                      v-model="extraDocLimit"
                      :items="[0,5,10]"
                      label="Number of documents"
                      box
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 pa-2>
                    <v-text-field
                      type="number"
                      v-model="docPrice"
                      label="Documents price $"
                      required
                      box
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm6 pa-2>
                    <v-select
                      v-model="quickApprove"
                      :items="['Quick Approve Lite', 'Quick Approve Plus', 'Confirmation Letter Lite', 'Confirmation Letter Plus', 'None']"
                      label="Quick approve"
                      box
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 pa-2>
                    <v-text-field
                      v-if="quickApprove != 'None'"
                      type="number"
                      v-model="quickApprovePrice"
                      label="Quick approve price $"
                      required
                      box
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 pa-2>
                    <v-checkbox
                      color="primary"
                      label="QA letter customization?"
                      v-model="qaLetter"
                      @change="handleResetLetterPrice"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm6 pa-2>
                    <v-text-field
                      type="number"
                      v-model="qaLetterPrice"
                      label="QA letter customization price $"
                      v-if="qaLetter"
                      min="30"
                      required
                      box
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 pa-2>
                    <v-checkbox
                      color="primary"
                      label="Deluxe fee"
                      v-model="deluxeFee"
                      @change="handleResetDeluxeFee"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm6 pa-2>
                    <v-text-field
                      type="number"
                      v-model="deluxeFeePrice"
                      label="Deluxe fee price $"
                      v-if="deluxeFee"
                      min="100"
                      required
                      box
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 pa-2>
                    <v-text-field
                      type="number"
                      v-model="proofOfIncomePrice"
                      label="Proof of income price $"
                      required
                      box
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-flex xs12 mb-3>
                  <v-card class="mb-4 px-2 elevation-0">
                    <h3 class="subheading d-inline-block blue--line px-2">
                      <v-checkbox color="primary" label="Rental Package" v-model="allowLease"></v-checkbox>
                    </h3>
                    <v-layout row wrap>
                      <v-flex xs12 sm6 pa-2>
                        <v-select
                          v-model="tenantPackageId"
                          :items="packages"
                          item-value="id"
                          item-text="description"
                          label="Individuals"
                          :disabled="!allowLease"
                        ></v-select>
                      </v-flex>
                      <v-flex xs12 sm6 pa-2>
                        <v-select
                          v-model="tenantSpousePackageId"
                          :items="packages"
                          item-value="id"
                          item-text="description"
                          label="Married Couples"
                          :disabled="!allowLease"
                        ></v-select>
                      </v-flex>
                      <v-flex xs12 pa-2>
                        <v-checkbox
                          color="primary"
                          label="Proof of income rental?"
                          v-model="proofOfIncomeRental"
                          :disabled="!allowLease"
                        ></v-checkbox>
                      </v-flex>
                    </v-layout>
                  </v-card>
                  <v-card class="mb-4 px-2 elevation-0">
                    <h3 class="subheading d-inline-block blue--line px-2">
                      <v-checkbox color="primary" label="Purchase Package" v-model="allowPurchase"></v-checkbox>
                    </h3>
                    <v-layout row wrap>
                      <v-flex xs12 sm6 pa-2>
                        <v-select
                          v-model="purchasePackageId"
                          :items="packages"
                          item-value="id"
                          item-text="description"
                          label="Individuals"
                          :disabled="!allowPurchase"
                        ></v-select>
                      </v-flex>
                      <v-flex xs12 sm6 pa-2>
                        <v-select
                          v-model="purchaseSpousePackageId"
                          :items="packages"
                          item-value="id"
                          item-text="description"
                          label="Married Couples"
                          :disabled="!allowPurchase"
                        ></v-select>
                      </v-flex>
                      <v-flex xs12 pa-2>
                        <v-checkbox
                          color="primary"
                          label="Proof of income purchase?"
                          v-model="proofOfIncomePurchase"
                          :disabled="!allowPurchase"
                        ></v-checkbox>
                      </v-flex>
                    </v-layout>
                  </v-card>
                  <v-card class="mb-4 px-2 elevation-0">
                    <h3 class="subheading d-inline-block blue--line px-2">
                      <v-checkbox color="primary" label="Guest Package" v-model="allowGuest"></v-checkbox>
                    </h3>
                    <v-layout row wrap>
                      <v-flex xs12 sm6 pa-2>
                        <v-select
                          v-model="guestPackageId"
                          :items="packages"
                          item-value="id"
                          item-text="description"
                          label="Individuals"
                          :disabled="!allowGuest"
                        ></v-select>
                      </v-flex>
                      <v-flex xs12 sm6 pa-2>
                        <v-select
                          v-model="guestSpousePackageId"
                          :items="packages"
                          item-value="id"
                          item-text="description"
                          label="Married Couples"
                          :disabled="!allowGuest"
                        ></v-select>
                      </v-flex>
                      <v-flex xs12 pa-2>
                        <v-checkbox
                          color="primary"
                          label="Proof of income guest?"
                          v-model="proofOfIncomeGuest"
                          :disabled="!allowGuest"
                        ></v-checkbox>
                      </v-flex>
                    </v-layout>
                  </v-card>
                  <v-card class="mb-4 px-2 elevation-0">
                    <h3
                      class="subheading d-inline-block blue--line py-3 px-2 mb-3"
                    >Fees charged to the applicants</h3>
                    <v-layout row wrap>
                      <v-flex xs12 sm4 px-2>
                        <v-text-field type="number" v-model="tenantFee" label="Individual" required></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 px-2>
                        <v-text-field
                          type="number"
                          v-model="tenantSpouseFee"
                          label="Married couple"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 px-2>
                        <v-text-field
                          type="number"
                          v-model="tenantRoommateFee"
                          label="Roomates (others)"
                          required
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                    <v-layout row wrap>
                      <v-flex xs12 sm4 px-2>
                        <v-text-field
                          type="number"
                          v-model="guestFee"
                          label="Guest application"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 px-2>
                        <v-text-field
                          type="number"
                          v-model="guestSpouseFee"
                          label="Guest Married couple"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 px-2>
                        <v-text-field
                          type="number"
                          v-model="guestRoommateFee"
                          label="Guest Roomates (others)"
                          required
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-card>
                  <v-card class="mb-4 px-2 elevation-0">
                    <h3
                      class="subheading d-inline-block blue--line py-3 px-2 mb-2"
                    >Rush handling fee</h3>
                    <v-layout row wrap>
                      <v-flex xs12 sm4 pa-2>
                        <v-text-field
                          type="number"
                          v-model="rushFee"
                          label="Charged to applicant"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 pa-2>
                        <v-text-field
                          type="number"
                          v-model="rushFeeRetained"
                          label="Retained by TE"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 pa-2>
                        <v-text-field
                          type="number"
                          :value="rushFeeDue"
                          label="Due to client"
                          readonly
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex xs12 sm12 mb-5 text-xs-center>
                  <template v-if="propertyId&&status=='Pending'">
                    <v-layout align-center justify-space-between row>
                      <v-btn
                        color="red"
                        class="white--text"
                        outline
                        large
                        depressed
                        @click="confirmCancel"
                      >Cancel Invitation</v-btn>
                      <v-btn
                        color="primary"
                        class="white--text"
                        outline
                        large
                        depressed
                        @click="confirmSend"
                      >Resend Invitation</v-btn>
                    </v-layout>
                  </template>
                  <v-btn
                    color="primary"
                    large
                    depressed
                    @click="confirmSend"
                    v-if="!propertyId"
                  >Invite Now</v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-flex>
      </v-layout>
    </v-content>
    <v-dialog v-model="showSendDialog" width="600">
      <v-card>
        <v-card-title class="headline grey white--text" primary-title>
          <span>Review &amp; Confirm</span>
          <v-spacer />
          <v-btn icon color="white" flat @click="showSendDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12 sm6 mb-3>
              <div class="grey--text">Community Name</div>
              {{ propertyName || '-'}}
            </v-flex>
            <v-flex xs12 sm6 mb-3>
              <div class="grey--text">Company Manager</div>
              {{ subscriberName || '-'}}
            </v-flex>
            <v-flex xs12 sm6 mb-3>
              <div class="grey--text">Editor Firstname</div>
              {{ editorFirstName || '-'}}
            </v-flex>
            <v-flex xs12 sm6 mb-3>
              <div class="grey--text">Editor Lastname</div>
              {{ editorLastName || '-'}}
            </v-flex>
            <v-flex xs12 sm6 mb-3>
              <div class="grey--text">Editor Email</div>
              {{ editorEmail || '-'}}
            </v-flex>
            <v-flex xs12 sm6 mb-3>
              <div class="grey--text">Sell Representative</div>
              {{ salesRepName || '-'}}
            </v-flex>
            <v-flex xs12 sm6 mb-3 v-if="hasCommission">
              <div class="grey--text">Commission Schedule</div>
              {{commissionYears[0] + "%, " + commissionYears[1] + "%, " + commissionYears[2] + "%, " + commissionYears[3] + "%, " + commissionYears[4] + "%" || '-'}}
            </v-flex>
            <v-flex xs12 sm4 mb-3>
              <div class="grey--text">Number of documents</div>
              {{ extraDocLimit || '-'}}
            </v-flex>
            <v-flex xs12 sm4 mb-3>
              <div class="grey--text">Documents price</div>
              {{ docPrice || '-'}}
            </v-flex>
            <v-flex xs12 sm4 mb-3>
              <div class="grey--text">Quick approve</div>
              {{ quickApprove || '-'}}
            </v-flex>
            <v-flex xs12 sm4 mb-3>
              <div class="grey--text">Quick approve price</div>
              {{ quickApprovePrice || '-'}}
            </v-flex>
            <v-flex xs12 sm4 mb-3>
              <div class="grey--text">QA letter customization?</div>
              {{ qaLetter || '-'}}
            </v-flex>
            <v-flex xs12 sm4 mb-3>
              <div class="grey--text">QA letter customization price</div>
              {{ qaLetterPrice || '-'}}
            </v-flex>
            <v-flex xs12 sm4 mb-3>
              <div class="grey--text">Deluxe fee</div>
              {{ deluxeFee || '-'}}
            </v-flex>
            <v-flex xs12 sm4 mb-3>
              <div class="grey--text">Deluxe fee price</div>
              {{ deluxeFeePrice || '-'}}
            </v-flex>
            <v-flex xs12 sm4 mb-3>
              <div class="grey--text">Proof of income rental?</div>
              {{ proofOfIncomeRental || '-'}}
            </v-flex>
            <v-flex xs12 sm4 mb-3>
              <div class="grey--text">Proof of income purchase?</div>
              {{ proofOfIncomePurchase || '-'}}
            </v-flex>
            <v-flex xs12 sm4 mb-3>
              <div class="grey--text">Proof of income guest?</div>
              {{ proofOfIncomeGuest || '-'}}
            </v-flex>
            <v-flex xs12 sm4 mb-3>
              <div class="grey--text">Proof of income price</div>
              {{ proofOfIncomePrice || '-'}}
            </v-flex>
            <v-flex xs12 mb-3>
              <strong>Rental Package</strong>
            </v-flex>
            <v-flex xs12 sm4 mb-3>
              <div class="grey--text">Individuals</div>
              {{tenantPackageId || '-'}}
            </v-flex>
            <v-flex xs12 sm4 mb-3>
              <div class="grey--text">Married Couples</div>
              {{tenantSpousePackageId || '-'}}
            </v-flex>
            <v-flex xs12 mb-3>
              <strong>Purchase Package</strong>
            </v-flex>
            <v-flex xs12 sm4 mb-3>
              <div class="grey--text">Individuals</div>
              {{purchasePackageId || '-'}}
            </v-flex>
            <v-flex xs12 sm4 mb-3>
              <div class="grey--text">Married Couples</div>
              {{purchaseSpousePackageId || '-'}}
            </v-flex>
            <v-flex xs12 mb-3>
              <strong>Guest Package</strong>
            </v-flex>
            <v-flex xs12 sm4 mb-3>
              <div class="grey--text">Individuals</div>
              {{guestPackageId || '-'}}
            </v-flex>
            <v-flex xs12 sm4 mb-3>
              <div class="grey--text">Married Couples</div>
              {{guestSpousePackageId || '-'}}
            </v-flex>
            <v-flex xs12 mb-3>
              <strong>Fees charged to the applicants</strong>
            </v-flex>
            <v-flex xs12 sm4 mb-3>
              <div class="grey--text">Individuals</div>
              {{tenantFee || '-'}}
            </v-flex>
            <v-flex xs12 sm4 mb-3>
              <div class="grey--text">Married Couples</div>
              {{tenantSpouseFee || '-'}}
            </v-flex>
            <v-flex xs12 sm4 mb-3>
              <div class="grey--text">Roomates (others)</div>
              {{tenantRoommateFee || '-'}}
            </v-flex>
            <v-flex xs12 sm4 mb-3>
              <div class="grey--text">Guest application</div>
              {{guestFee || '-'}}
            </v-flex>
            <v-flex xs12 sm4 mb-3>
              <div class="grey--text">Guest Married Couples</div>
              {{guestSpouseFee || '-'}}
            </v-flex>
            <v-flex xs12 sm4 mb-3>
              <div class="grey--text">Guest Roomates (others)</div>
              {{guestRoommateFee || '-'}}
            </v-flex>
            <v-flex xs12 mb-3>
              <strong>Rush handling fee</strong>
            </v-flex>
            <v-flex xs12 sm4 mb-3>
              <div class="grey--text">Charged to applicant</div>
              {{rushFee || '-'}}
            </v-flex>
            <v-flex xs12 sm4 mb-3>
              <div class="grey--text">Retained by TE</div>
              {{rushFeeRetained || '-'}}
            </v-flex>
            <v-flex xs12 sm4 mb-3>
              <div class="grey--text">Due to client</div>
              {{rushFeeDue || '-'}}
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="isLoading"
            v-bind:class="{'red--text': isLoading, 'white--text': !isLoading}"
            :disabled="isLoading"
            large
            depressed
            @click="inviteNow"
            v-text="propertyId&&status=='Pending'?'Resend Invitation':'Send Invitation'"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showCancelDialog" width="600">
      <v-card>
        <v-card-title class="headline grey white--text" primary-title>
          <span>Confirm Cancel</span>
          <v-spacer />
          <v-btn icon color="white" flat @click="showCancelDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>When canceling the invitation the property manager will not be able to access the property. Are you sure you want to cancel it?</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            :loading="isLoading"
            large
            depressed
            v-bind:class="{'red--text': isLoading, 'white--text': !isLoading}"
            :disabled="isLoading"
            @click="cancelNow"
            v-text="'Yes, Cancel Invitation'"
          ></v-btn>
          <v-btn
            color="red"
            outline
            large
            depressed
            :disabled="isLoading"
            @click="showCancelDialog=false"
            v-text="'No'"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-content v-if="!isAddProperty">
      <v-layout row wrap pt-4>
        <v-flex xs12 sm12 class="mb-5">
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 sm8 offset-sm2 class="mb-5">
                <v-layout wrap align-center justify-space-between row>
                  <h4 class="ma-0">SUBSCRIBERS</h4>
                </v-layout>
                <v-text-field
                  v-model="query"
                  label="Search for a subscriber..."
                  append-icon="search"
                  :autofocus="$isDesktop()"
                  clearable
                  single-line
                  box
                ></v-text-field>
                <div class="text-xs-center py-4" v-if="isLoading">
                  <v-progress-circular :size="50" color="primary" indeterminate />
                </div>
                <v-list
                  two-line
                  subheader
                  :class="{'pb-0': $vuetify.breakpoint.smAndDown}"
                  v-if="!isLoading&&subscribers.length"
                >
                  <v-list-tile
                    v-for="(item, index) in subscribers"
                    :key="index"
                    avatar
                    @click="addProperty({ subscriberId: item.id, subscriberName: item.description, disablePricingInformation: item.disablePricingInformation })"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>{{ item.description }}</v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-chip color="primary" text-color="white" class="chip-fluid">#{{ item.id }}</v-chip>
                    </v-list-tile-action>
                  </v-list-tile>
                </v-list>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
      <v-btn fixed dark fab bottom right color="red" to="/compliance">
        <v-icon>close</v-icon>
      </v-btn>
    </v-content>
  </v-app>
</template>
<script>
import { mapGetters } from "vuex";
import Validator from "../../libs/validator";
import UploadButton from "../../components/UploadButton";
import DisableAutocomplete from "../../mixin/DisableAutocomplete";

export default {
  mixins: [DisableAutocomplete],
  data() {
    const validator = new Validator();
    return {
      isAddProperty: false,
      isUploading: false,
      isLoading: false,
      isLoadingInvitation: false,
      showSendDialog: false,
      showCancelDialog: false,

      subscribers: [],
      query: null,

      propertyId: null,

      subscriberId: null,
      subscriberName: null,
      disablePricingInformation: false,

      propertyName: null,
      pmFirstName: null,
      pmLastName: null,
      pmEmail: null,

      editorFirstName: null,
      editorLastName: null,
      editorEmail: null,

      proposalDocUrl: null,
      proposalDoc: null,
      docPrice: 7,
      extraDocLimit: 5,

      salesRepId: null,
      hasCommission: false,
      commissionValue: 0,
      commissionYears: Array(5),

      tenantPackageId: null,
      tenantSpousePackageId: null,
      purchasePackageId: null,
      purchaseSpousePackageId: null,
      guestPackageId: null,
      guestSpousePackageId: null,

      tenantFee: 150,
      tenantSpouseFee: 150,
      tenantRoommateFee: 300,
      guestFee: 150,
      guestSpouseFee: 150,
      guestRoommateFee: 300,

      rushFee: 45,
      rushFeeRetained: 45,

      tenantExtraDocLimit: null,
      purchaseExtraDocLimit: null,
      guestExtraDocLimit: null,

      allowLease: false,
      allowPurchase: false,
      allowGuest: false,

      packages: [],
      salesreps: [],

      created: null,
      updated: null,
      status: null,

      quickApprove: 'Lite',
      quickApprovePrice: 3,

      qaLetter: false,
      qaLetterPrice: 30,

      deluxeFee: false,
      deluxeFeePrice: 200,

      proofOfIncomeRental: false,
      proofOfIncomePurchase: false,
      proofOfIncomeGuest: false,
      proofOfIncomePrice: 15,

      rules: {
        required: [validator.rules.required("This field is required.")],
        email: [
          validator.rules.required("E-mail is required."),
          validator.rules.email("Invalid email.")
        ]
      }
    };
  },
  watch: {
    query(val) {
      const _this = this;
      this.isLoading = true;
      this.$http
        .get("/manager/subscribers/" + val)
        .then(response => {
          _this.subscribers = response.data || [];
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
        })
        .finally(() => (this.isLoading = false));
    },
    extraDocLimit: function (val) {
      if (val==0) this.docPrice = 0
      else if (val==5)  this.docPrice = 7
      else if (val==10)  this.docPrice = 12
    },
    quickApprove: function (val) {
      if (val.toLocaleLowerCase().includes('lite'))
        this.quickApprovePrice = 3;
      else if (val.toLocaleLowerCase().includes('plus'))
        this.quickApprovePrice = 6;
      else if (val=='None')
        this.quickApprovePrice = 0;
    },
  },
  components: {
    UploadButton
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser"
    }),
    rushFeeDue() {
      return this.rushFee == null ? null : this.rushFee - this.rushFeeRetained;
    },
    salesRepName () {
      const found = this.salesreps.find(element => element.id == this.salesRepId)
      return (found) ? found.name : ""
    },
    currentSalesRep () {
      const found = this.salesreps.find(element => element.id == this.salesRepId)
      return found;
    }
  },
  created() {
    this.checkCurrentLogin();
  },
  updated() {
    this.checkCurrentLogin();
  },
  methods: {
    deleteCommission(){
      if (!this.salesRepId){
        this.hasCommission = false;
        this.commissionYears = Array(0);
        this.salesRepId = null;
      }
    },
    checkCurrentLogin() {
      const now = Math.floor(Date.now() / 1000);
      if (
        !this.currentUser ||
        !this.currentUser.isLoggedIn ||
        !localStorage.token ||
        now >= this.currentUser.expire
      ) {
        this.$router.push("/login?redirect=" + this.$route.path);
      }
      this.currentUser.isCompliance = this.currentUser.auth.includes('ROLE_L_CM')
      this.currentUser.isManager = this.currentUser.auth.includes('ROLE_L_PM')
      this.currentUser.isEditor = this.currentUser.auth.includes('ROLE_PEND_PROP_ED')
    },
    uploadProposalDoc(e) {
      this.proposalDoc = e.data;
      this.proposalDocUrl = e.name;
    },
    alertSuccess(message) {
      this.$events.$emit("showSnackbar", {
        message: message,
        color: "green"
      });
    },
    alertError(message) {
      this.$events.$emit("showSnackbar", {
        message: message,
        color: "red"
      });
    },
    addProperty(item) {
      this.subscriberId = item.subscriberId;
      this.subscriberName = item.subscriberName;
      this.disablePricingInformation = item.disablePricingInformation;
      this.loadPackages();
      this.loadSalesReps();
      this.isAddProperty = true;
    },
    loadSalesReps() {
      const _this = this;
      this.$http
        .get("/manager/sales-reps")
        .then(response => {
          _this.salesreps = response.data;
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
        });
    },
    loadPackages() {
      const _this = this;
      this.$http
        .get("/manager/subscriber-packages/" + this.subscriberId)
        .then(response => {
          _this.packages = response.data;
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
        });
    },
    confirmCancel() {
      this.showCancelDialog = true;
    },
    cancelNow() {
      const _this = this;
      this.isLoading = true;
      this.$http
        .post("/manager/properties/invitation/cancel", {
          propertyId: this.propertyId
        })
        .then(response => {
          // eslint-disable-next-line
          console.log(response);
          _this.alertSuccess("Invitation Canceled!");
          _this.$router.push("/compliance");
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
          _this.alertError(
            e.error || "An error occurred while processing your request."
          );
        })
        .finally(() => {
          _this.showCancelDialog = false;
          _this.isLoading = false;
        });
    },
    confirmSend() {
      if (!this.$refs.form.validate()) {
        console.log('1')
        return;
      }

      if (this.hasCommission) {
        console.log('2')
        for (var i = 0; i < this.commissionYears.length; i++) {
          if (!this.commissionYears[i]) {
            console.log('3')
            return this.alertError("Commission year is required.");
          } else if (this.commissionYears[i] < 0) {
            console.log('4')
            return this.alertError("Commission cannot be negative.");
          } else if (this.commissionYears[i] > this.currentSalesRep['commission'+(i+1)+'year']) {
            console.log('5')
            return this.alertError("The commission schedule cannot exceed the standard commission");
          }
        }
      }

      if (this.qaLetterPrice < 30){
        return this.alertError("QA letter price must be greater than or equal to 30.");
      }

      if (this.deluxeFeePrice < 100){
        return this.alertError("Deluxe fee price must be greater than or equal to 100.");
      }

      if (!this.allowLease && !this.allowPurchase && !this.allowGuest) {
        return this.alertError("Please select a package.");
      }

      if (this.allowLease && (!this.tenantPackageId || !this.tenantSpousePackageId)) {
        return this.alertError("Please select Rental Packages.");
      }

      if (this.allowPurchase && (!this.purchasePackageId || !this.purchaseSpousePackageId)) {
        return this.alertError("Please select Purchase Packages.");
      }

      if (this.allowGuest && (!this.guestPackageId || !this.guestSpousePackageId)) {
        return this.alertError("Please select Guest Packages.");
      }

      if (!this.proposalDocUrl && !this.disablePricingInformation){
        return this.alertError("Please upload a proposal.");
      }

      this.showSendDialog = true;
    },
    inviteNow() {
      const _this = this;

      if (!this.$refs.form.validate()) {
        return;
      }

      this.isLoading = true;

      const data = {
        subscriberId: this.subscriberId,

        propertyName: this.propertyName,

        editorFirstName: this.editorFirstName,
        editorLastName: this.editorLastName,
        editorEmail: this.editorEmail,

        docPrice: parseInt(this.docPrice),
        proposalDoc: this.proposalDoc,

        pmFirstName: this.pmFirstName,
        pmLastName: this.pmLastName,
        pmEmail: this.pmEmail,

        salesRepId: this.salesRepId,
        hasCommission: this.hasCommission,
        commissionValue: parseInt(this.commissionValue),
        commissionYears: this.commissionYears,

        allowLease: this.allowLease,
        allowPurchase: this.allowPurchase,
        allowGuest: this.allowGuest,

        tenantFee: parseInt(this.tenantFee),
        tenantSpouseFee: parseInt(this.tenantSpouseFee),
        tenantRoommateFee: parseInt(this.tenantRoommateFee),
        guestFee: parseInt(this.guestFee),
        guestSpouseFee: parseInt(this.guestSpouseFee),
        guestRoommateFee: parseInt(this.guestRoommateFee),

        rushFee: parseInt(this.rushFee),
        rushFeeRetained: parseInt(this.rushFeeRetained),

        quickApprove: this.quickApprove,
        quickApprovePrice: parseInt(this.quickApprovePrice),
        qaLetter: this.qaLetter,
        qaLetterPrice: parseInt(this.qaLetterPrice),
        deluxeFee: this.deluxeFee,
        deluxeFeePrice: parseInt(this.deluxeFeePrice),
        proofOfIncomeRental: this.proofOfIncomeRental,
        proofOfIncomePurchase: this.proofOfIncomePurchase,
        proofOfIncomeGuest: this.proofOfIncomeGuest,
        proofOfIncomePrice: parseInt(this.proofOfIncomePrice),

      };

      if (this.allowLease) {
        data.tenantPackageId = this.tenantPackageId;
        data.tenantSpousePackageId = this.tenantSpousePackageId;
        data.tenantExtraDocLimit = this.extraDocLimit;
      }

      if (this.allowPurchase) {
        data.purchasePackageId = this.purchasePackageId;
        data.purchaseSpousePackageId = this.purchaseSpousePackageId;
        data.purchaseExtraDocLimit = this.extraDocLimit;
      }

      if (this.allowGuest) {
        data.guestPackageId = this.guestPackageId;
        data.guestSpousePackageId = this.guestSpousePackageId;
        data.guestExtraDocLimit = this.extraDocLimit;
      }

      let action = "new";
      if (this.propertyId) {
        action = "invitation/resend";
        data.propertyId = this.propertyId;
      }

      if (!this.hasCommission){
        console.log(this.salesRepId, this.salesreps);
        const currentSalesRep = this.salesreps.find((e) => e.id === this.salesRepId);
        if (currentSalesRep){
          this.commissionYears[0] = currentSalesRep.commission1year;
          this.commissionYears[1] = currentSalesRep.commission2year;
          this.commissionYears[2] = currentSalesRep.commission3year;
          this.commissionYears[3] = currentSalesRep.commission4year;
          this.commissionYears[4] = currentSalesRep.commission5year;
        }
      }

      this.$http
        .post("/manager/properties/" + action, data)
        .then(() => {
          _this.alertSuccess("Invitation Sent!");
          _this.$router.push("/compliance");
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
          _this.alertError(
            e.error || "An error occurred while processing your request."
          );
        })
        .finally(() => {
          _this.showSendDialog = false;
          _this.isLoading = false;
        });
    },
    loadInvitation(id) {
      const _this = this;
      this.isAddProperty = true;
      this.isLoadingInvitation = true;
      this.$http
        .get("/manager/properties/invitation/" + id)
        .then(response => {
          for (let key in response.data) {
            _this[key] = response.data[key];
          }

          _this.extraDocLimit =
            _this.tenantExtraDocLimit ||
            _this.purchaseExtraDocLimit ||
            _this.guestExtraDocLimit;

          _this.loadPackages();
          _this.loadSalesReps();

          if (_this.docPrice === 0) _this.docPrice = '0';

          if (_this.status != "Pending") {
            _this.alertError("Property status is " + _this.status);
            //_this.$router.push('/compliance')
          }
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e);
          _this.alertError(
            e.error || "An error occurred while processing your request."
          );
        })
        .finally(() => {
          _this.isLoadingInvitation = false;
        });
    },
    handleResetLetterPrice(e){
      if (!e){
        this.qaLetterPrice = 30;
      }
    },
    handleResetDeluxeFee(e){
      if (!e){
        this.deluxeFeePrice = 100;
      }
    }
  },
  mounted() {
    if (!this.currentUser.isCompliance) {
      return this.$router.push("/properties");
    }

    if (this.$route.params.id) {
      this.loadInvitation(this.$route.params.id);
    }
  }
};
</script>
<style>
.v-dialog .v-card__text {
  max-height: 300px;
  overflow: scroll;
}

.blue--line {
  border-bottom: 2px solid #2196f3;
}


</style>
